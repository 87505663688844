import fotoCircular from '../../assets/images/pepa.jpg'
import styles from './PaginaProposito.module.scss'
import cx from 'classnames'

const PaginaProposito = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.quemSouEuContainer}>
      <section className={styles.cardQuemSouEu}>
        <h2>
          Quem sou eu e qual o meu propósito?
        </h2>
        <label className={styles.text}>
        Me chamo Francis, sou fonoaudióloga graduada com láurea acadêmica no ano de 2019, sou mãe e o meu dia-a-dia é tomado por interagir com crianças e ajudá-las a se comunicarem melhor. Cada conquista é sempre comemorada como se fosse a primeira. Para mim que sou apaixonada por comunicação, ser fonoaudióloga é algo que me traz muita gratidão, acredito que ajudar que futuros adultos se tornem bons falantes pode ter relevância na construção de um mundo melhor.
        </label>
      </section>
      <section className={cx(styles.cardQuemSouEu, styles.containerSegundoBloco)}>
        <div className={styles.fotoContainer}>
          <img src={fotoCircular} alt="" />
        </div>
      </section>
    </section>
  </section>
)

export default PaginaProposito