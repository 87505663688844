import doubleArrowDown from '../../assets/images/double-arrow-down.png'
import fotoCircular from '../../assets/images/pepa.jpg'
import styles from './PaginaApresentacao.module.scss'
import cx from 'classnames'

const PaginaApresentacao = () => (
  <section className={cx(styles.pageCard, styles.backgroundNotebook)}>
    <section className={styles.firstBlock}>
      <section className={styles.text}>
        <p>
          Você tem uma criança a partir de 4 anos de idade e que precisa de atendimento fonoaudiólógico?
        </p>
        <p>
          Eu posso te ajudar e é tudo por aqui, de forma remota!
        </p>
      </section>
      <div className={styles.imagemComAssinatura}>
        <div className={styles.circularPortrait}>
          <img src={fotoCircular} />
        </div>
        <p className={styles.nomeDaFono}>Fonoaudióloga Francis Guimarães</p>
        <img src={doubleArrowDown} alt="Menu Bar" />
      </div>
    </section>
    <section className={styles.secondBlock}>
    </section>
  </section>
)

export default PaginaApresentacao