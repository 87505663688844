import homeIcon from '../../assets/images/home-icon.png'
import worldIcon from '../../assets/images/world-icon.png'
import clockIcon from '../../assets/images/clock-icon.png'
import savingMoneyIcon from '../../assets/images/saving-money.png'
import styles from './PaginaBeneficios.module.scss'
import cx from 'classnames'

const PaginaBeneficios = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.headerBeneficios}>
      <div className={styles.text}>
        <p>Benefícios do tele atendimento <br/>em fonoaudiologia</p>
      </div>
    </section>
    <section className={styles.cardsContainer}>
      <div className={styles.cardContainer}>
        <div className={cx(styles.circularIcon,  styles.purple)}>
          <img src={homeIcon} alt="" />
        </div>
        <div className={cx(styles.cardBeneficios, styles.purpleBorder)}>
          <h3>No Conforto do seu lar</h3>
          <p>Receba terapia sem sair de casa, promovendo um ambiente confortável e familiar.</p>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={cx(styles.circularIcon, styles.orange)}>
          <img src={worldIcon} alt="" />
        </div>
        <div className={cx(styles.cardBeneficios, styles.orangeBorder)}>
          <h3>Em qualquer lugar do mundo</h3>
          <p>Acesso à terapia de fonoaudiologia de qualquer lugar, eliminando barreiras geográficas.</p>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={cx(styles.circularIcon, styles.purple)}>
          <img src={clockIcon} alt="" />
        </div>
        <div className={cx(styles.cardBeneficios, styles.purpleBorder)}>
          <h3>Mais flexibilidade</h3>
          <p>É ideal para pessoas com agendas lotadas ou limitações de mobilidade, assegurando apoio sem conflitos de horário.</p>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={cx(styles.circularIcon, styles.orange)}>
          <img src={savingMoneyIcon} alt="" />
        </div>
        <div className={cx(styles.cardBeneficios, styles.orangeBorder)}>
          <h3>Mais economia</h3>
          <p>Redução de custos de deslocamento e opções de pagamento acessíveis, tornando a terapia mais econômica.</p>
        </div>
      </div>
    </section>
  </section>
)

export default PaginaBeneficios