import computadorSmartphoneImagem from '../../assets/images/voce_smartphone.jpg'
import kidComputer from '../../assets/images/kid-computer.webp'
import postura from '../../assets/images/postura.jpg'
import styles from './PaginaRealizarSessao.module.scss'

const PaginaRealizarSessao = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.headerRealizarSessao}>
      <div className={styles.text}>
        <span>O que é necessário para a realização de uma sessão?</span>
      </div>
    </section>
    <section className={styles.cardRealizarSessaoContainer}>
      <section className={styles.cardRealizarSessao}>
        <img src={computadorSmartphoneImagem} alt="" />
        <label className={styles.text}>
          Computador, tablet ou smartphone com uma câmera e microfone de boa qualidade para garantir uma comunicação clara
        </label>
      </section>
      <section className={styles.cardRealizarSessao}>
        <img src={kidComputer} alt="" />
        <label className={styles.text}>
          Escolha de um local tranquilo, bem iluminado e livre de distrações para realizar a sessão
        </label>
      </section>
      <section className={styles.cardRealizarSessao}>
        <img src={postura} alt="" />
        <label className={styles.text}>
          Escolha um local em que o paciente possa ficar bem posturado para a realização da sessão
        </label>
      </section>
    </section>
  </section>
)

export default PaginaRealizarSessao